<template>
  <div class="main-container">
    <div class="section-topic">
      <div class="title-header">
        <h1>{{ $t("overtime_money.title") }}</h1>
      </div>
    </div>
    <div class="section-action">
      <div class="search-action">
        <v-text-field class="btn btn-color btn-border btn-border-color" outlined dense prepend-inner-icon="mdi-magnify"
          :placeholder="$t('benefit.search')" v-model="searchItem" @keypress.enter="searchFilterItem">
        </v-text-field>
      </div>
      <div class="btn-new-create-action">
        <v-btn class="btn-create" @click="onCreate()">
          {{ $t("overtime_money.create") }}
        </v-btn>
      </div>
    </div>
    <div class="section-form-content">
      <div class="block-content">
        <table class="table table-view">
          <thead>
            <tr>
              <th class="text-left">{{$t("overtime_money.no")}}</th>
              <th class="text-left">{{ $t("overtime_money.type") }}</th>
              <th class="text-left">{{ $t("overtime_money.holiday") }}</th>
              <th class="text-left">{{ $t("overtime_money.start_time") }}-{{ $t("overtime_money.end_time") }}</th>
              <th class="text-left">{{ $t("overtime_money.table.rate") }}</th>
              <!-- <th class="text-left">
                {{ $t("overtime_money.table.calculate") }}
              </th> -->
              <th class="text-end"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in listOvertimeOt" :key="idx">
              <td>{{ idx + 1 }}</td>
              <td>{{ item.type }}</td>
              <td>{{ item.rest_day ? `${item.rest_day.name} (${item.rest_day_value})` : $t('overtime_money.normal') }}
              </td>
              <td class="text-primary">{{ item.start_time }} - {{ item.end_time }}</td>
              <td class="text-danger">{{ item.rate }}</td>
              <!-- <td>{{ item.calculate }}</td> -->
              <td class="text-end">
                <v-menu transition="slide-y-transition" bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined v-bind="attrs" v-on="on">
                      <i class="fas fa-ellipsis-v btn-ellipsis"></i>
                    </v-btn>
                  </template>
                  <v-list>
                    <!-- <v-list-item>
                      <v-list-item-title class="btn-edit" @click="onEdit(item.id)">Edit</v-list-item-title>
                    </v-list-item> -->
                    <v-list-item>
                      <v-list-item-title class="btn-delete" @click="onDeleted(item.id)">{{$t("education.delete")}}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <default-table-no-result v-else /> -->
        <!-- <Pagination
          v-if="pagination.total_pages > 1"
          :pagination="pagination"
          :offset="offset"
          @paginate="fetchOvertimeOt"
        >
        </Pagination> -->
        <ModalDelete fullscreen title="Delete Setting OT">
          <template v-slot="{ close }">
            <Delete :setting_ot_id="setting_ot_id" @close="close" @success="fetchOvertimeOt()" />
          </template>
        </ModalDelete>
        <Loading v-if="isLoading" />
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/Loading";
import Pagination from "@/components/Paginate/Pagination";
import defaultTableNoResult from "@/components/defaultTableNoResult";
import Delete from "./Crud/Delete.vue";

export default {
  components: {
    Loading,
    Pagination,
    defaultTableNoResult,
    Delete,
  },

  data() {
    return {
      searchItem: "",
      offset: 10,
      pagination: {},
      per_page: 10,
      listOvertimeOt: [],
      setting_ot_id: "",
      isLoading: true,
      modalDeletePosition: false,
    };
  },
  methods: {
    searchFilterItem() {
      this.fetchOvertimeOt();
      // this.pagination.current_page = 1;
    },
    onCreate() {
      this.$router
        .push({
          name: "setting_ot.create",
        })
        .catch(() => { });
    },
    onEdit(item) {
      this.$router.push({
        name: "setting_ot.update",
        params: {
          setting_ot_id: item,
        },
      });
    },

    onDeleted(setting_ot_id) {
      this.setting_ot_id = setting_ot_id;
      this.$store.commit("modalDelete_State", true);
    },

    fetchOvertimeOt() {
      this.isLoading = true;
      this.$axios
        .get(`company/ot/setting`, {
          params: {
            // page: this.pagination.current_page,
            // per_page: this.per_page,
            filter: this.searchItem,
          },
        })
        .then((res) => {
          if (res) {
            this.isLoading = false;
            this.listOvertimeOt = res.data.data;
          }
          // console.log(res.data.data);
        })
        .catch((error) => {
          console.log(error);
          this.isLoading = false;
        });
    },
  },
  created() {
    this.fetchOvertimeOt();
  },
};
</script>

<style scoped lang="scss"></style>
